import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const ICON_DEFINITIONS = {
  adjust: 'adjust',
  arrow: 'arrow',
  chart: 'chart',
  dashboard: 'dashboard',
  document: 'document',
  documents: 'documents',
  menu: 'menu',
  performance: 'performance',
  account: 'account',
  exchange: 'exchange',
  meteredfunding: 'meteredfunding',
  more_horiz: 'more_horiz',
  website: 'website',
  linkedin: 'linkedin',
  facebook: 'facebook',
  instagram: 'instagram',
  twitter: 'twitter',
  linkedin_dark: 'linkedin_dark',
  facebook_dark: 'facebook_dark',
  instagram_dark: 'instagram_dark',
  twitter_dark: 'twitter_dark',
  person: 'person',
  info: 'info',
  extension: 'extension',
  'chevron-right': 'chevron-right',
  dropdown: 'dropdown',
  more_vert: 'more_vert',
  cross: 'cross',
  check: 'check',
  check_white: 'check-white',
  selector: 'selector',
  dropup: 'dropup',
  plus: 'plus',
  edit: 'edit',
  trash: 'trash',
  'check-mark': 'check-mark',
  cancel: 'cancel',
  add: 'add',
  user: 'user',
  single_chart: 'single_chart',
  multiple_charts: 'multiple_charts',
  arrow_circle_down: 'arrow_circle_down',
  arrow_circle_up: 'arrow_circle_up',
  showpassword: 'showpassword',
  gmail: 'gmail',
  outlook: 'outlook',
  bar_chart: 'bar_chart',
  external_link: 'external_link',
  settings: 'settings',
  add_circle: 'add_circle',
  write_note: 'write_note',
  upload: 'upload',
  drag_reorder: 'drag_reorder',
  download: 'download',
  notification: 'notification',
  issue_shares: 'issue_shares',
  financing_round: 'financing_round',
  financials: 'financials',
  equity_plan: 'equity_plan',
  remove: 'remove',
  contribution: 'contribution',
  recur_contribution: 'recur_contribution',
  automated_contribution: 'automated_contribution',
  delete: 'delete',
  notification_bell: 'notification_bell',
  unchecked: 'unchecked',
  checked: 'checked',
  loans: 'loans',
  search_loop: 'search_loop',
  filter: 'filter',
  simfinancing_round: 'simfinancing_round',
  arrow_left_solid: 'arrow_left_solid',
  visibility: 'visibility',
  repeat: 'repeat',
  dialog: 'dialog',
  file_type_default: 'file_type_default',
  file_type_excel: 'file_type_excel',
  file_type_image: 'file_type_image',
  file_type_pdf: 'file_type_pdf',
  file_type_powerpoint: 'file_type_powerpoint',
  file_type_presentation: 'file_type_presentation',
  file_type_spreadsheets: 'file_type_spreadsheets',
  file_type_zip: 'file_type_zip',
  file_type_txt: 'file_type_txt',
  file_type_word: 'file_type_word',
  file_type_xml: 'file_type_xml',
  file_type_folder: 'file_type_folder',
  file_type_folder_opened: 'file_type_folder_opened',
  password_invisible: 'password-invisible',
  password_visible: 'password-visible',
  percent_zero: 'percent_zero',
  doc_upload: 'doc_upload',
  trash_full: 'trash-full',
  chevron_left: 'chevron_left',
  datepicker: 'datepicker',
  light: 'light',
  chevron: 'chevron',
  angle_down: 'angle-down',
  close_search: 'close-search',
  building_solid: 'building_solid',
  building_solid_person: 'building_solid_person',
  scenario_icon: 'scenario_icon',
  individual: 'individual',
  non_personal: 'non_personal',
  grouped: 'grouped',
  import_document: 'import-document',
  documents_blue: 'documents-blue',
  upload_document: 'upload_document',
  sorting: 'sorting',
  sorting_asc: 'sorting-asc',
  organisation: 'organisation',
  access_accepted: 'access_accepted',
  access_expired: 'access_expired',
  access_pending: 'access_pending',
  access_no: 'access_no',
  'selector-chevron': 'selector-chevron',
  drag_menu: 'drag_menu',
  envelope: 'envelope',
  share: 'share',
  'lock-secured': 'lock-secured',
  minus: 'minus',
  print: 'print',
  download_doc: 'download_doc',
  home: 'home',
  profile: 'profile',
  headset: 'headset',
  stakeholder: 'stakeholder',
  stakeholder_duo: 'stakeholder_duo',
  stakeholder_group: 'stakeholder_group',
  office: 'office',
  download_solid: 'download_solid',
  file_import_solid: 'file-import-solid',
  video_cam: 'videocam',
};

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  private readonly folder = 'assets/icons';

  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  addSvgIcons(): void {
    const iconDefinitions: { [key: string]: string } = ICON_DEFINITIONS;
    Object.keys(iconDefinitions).forEach((key) => {
      this.iconRegistry.addSvgIcon(
        key,
        this.sanitizer.bypassSecurityTrustResourceUrl(`${this.folder}/${iconDefinitions[key]}.svg`),
      );
    });
  }
}
