import { ManageSharesRoutes } from '@app/manage-shares/constants';
import { OnboardingRouteNames } from '@app/shared/types/enums/onboarding-route-names.enum';
import { ScenarioModelingRoutes } from '@app/scenario-modeling/constants/scenario-modeling-routes.enum';
import { CompanyRouteNames } from '@app/company/types/enums/company-route-names.enum';
import { EDIT_PATH, VIEW_PATH, CREATE_PATH, ROOT_PATH } from '@app/shared/utils/constants/common.constants';
import { AuthRoutes } from '@app/shared/types/enums/auth-routes.enum';
import { Section } from '@app/shared/types/enums/section.enum';
import { getUrlFromPath } from '@app/shared/utils/helpers/path.helpers';
import { FinancialRouteNames } from '@app/shared/types/enums';
import { ParticipantRouteNames } from '@app/participant/types/enums/participant-route-names.enum';

// TODO check possibility to split into several area-specific constants files
const AUTH_PATH = 'auth';
const ACCEPTED_INVITE = AuthRoutes.ACCEPTED_INVITE;
const EXPIRED_INVITE = AuthRoutes.EXPIRED_INVITE;
const ACCESS_REVOKED = AuthRoutes.ACCESS_REVOKED;
export const loginPath = [`/${AUTH_PATH}`, 'login'];
export const ACCEPTED_INVITE_PATH = [AUTH_PATH, ACCEPTED_INVITE];
export const EXPIRED_INVITE_PATH = [AUTH_PATH, EXPIRED_INVITE];
export const ACCESS_REVOKED_PATH = [AUTH_PATH, ACCESS_REVOKED];
export const RESET_PASSWORD_SUCCESS_PATH = [AUTH_PATH, AuthRoutes.RESET_PASSWORD_SUCCESS];
export const BACK_ARROW_URLS = [`/${AUTH_PATH}/login`, `/${AUTH_PATH}/register`];
export const DEMO_WELCOME_URL = '/demo/welcome';
export const paymentNeededPath = [`/${AUTH_PATH}`, 'payment-needed'];
export const settingsImportPath = ['/', CompanyRouteNames.COMPANY, 'settings', 'company', 'import-and-integrations'];
export const settingsExternalSourcesPath = ['/', CompanyRouteNames.COMPANY, 'settings', 'company', 'external-sources'];
export const defaultRoutePath = ['/', CompanyRouteNames.COMPANY];
export const financialsProfitAndLossPath = [
  '/',
  CompanyRouteNames.COMPANY,
  FinancialRouteNames.FINANCIALS,
  FinancialRouteNames.PROFIT_LOSS,
];
export const creatingCompanyOnboardingPath = ['/', OnboardingRouteNames.ONBOADING];
export const documentsOnboardingPath = ['/', CompanyRouteNames.COMPANY, 'documents', 'onboarding'];
export const dashboardPath = ['/', CompanyRouteNames.COMPANY, 'equity', 'dashboard'];
export const capTablePath = ['/', CompanyRouteNames.COMPANY, 'equity', 'cap-table'];
export const capTableAddSharesPath = [
  ROOT_PATH,
  CompanyRouteNames.COMPANY,
  'equity',
  'cap-table',
  ManageSharesRoutes.MANAGE_SHARES,
  CREATE_PATH,
];

export const ScenarioModelingPath = ['/', CompanyRouteNames.COMPANY, ScenarioModelingRoutes.SCENARIO_MODELING];
export const ScenarioModelingCreateScenarioPath = [
  '/',
  CompanyRouteNames.COMPANY,
  ScenarioModelingRoutes.SCENARIO_MODELING,
  ScenarioModelingRoutes.CREATE_SCENARIO,
];

export const getScenarioModelingViewScenarioPath = (id: number): string[] => [
  '/',
  CompanyRouteNames.COMPANY,
  ScenarioModelingRoutes.SCENARIO_MODELING,
  `${id}`,
  VIEW_PATH,
];

export const getScenarioModelingEditScenarioPath = (id: number): string[] => [
  '/',
  CompanyRouteNames.COMPANY,
  ScenarioModelingRoutes.SCENARIO_MODELING,
  `${id}`,
  EDIT_PATH,
];

export const getCapTableEditSharesPath = (id: number): string[] => [
  ROOT_PATH,
  CompanyRouteNames.COMPANY,
  'equity',
  'cap-table',
  ManageSharesRoutes.MANAGE_SHARES,
  `${id}`,
  EDIT_PATH,
];

const BASE_COMPANY_PATH = [ROOT_PATH, CompanyRouteNames.COMPANY];
const BASE_EQUITY_PATH = [...BASE_COMPANY_PATH, 'equity'];
const BASE_PERFORMANCE_PATH = [...BASE_COMPANY_PATH, 'performance'];

export const SECTION_PATH: Record<Section, string[]> = {
  [Section.home]: [...BASE_COMPANY_PATH, 'home'],
  [Section.dashboard]: [...BASE_EQUITY_PATH, 'dashboard'],
  [Section.capTable]: [...BASE_EQUITY_PATH, 'cap-table'],
  [Section.loansAndConvertibles]: [...BASE_EQUITY_PATH, 'commitments'],
  [Section.financials]: [...BASE_COMPANY_PATH, FinancialRouteNames.FINANCIALS],
  [Section.kpis]: [...BASE_PERFORMANCE_PATH, 'kpis'],
  [Section.documents]: [...BASE_COMPANY_PATH, 'documents'],
  [Section.slicingPie]: [...BASE_COMPANY_PATH, 'framework'],
  [Section.scenarioModeling]: [...BASE_COMPANY_PATH, 'scenario-modeling'],
  [Section.settings]: [...BASE_COMPANY_PATH, 'settings'],
  [Section.participant]: [ParticipantRouteNames.PARTICIPANT],
  [Section.companySettings]: [...BASE_COMPANY_PATH, 'settings', 'company'],
  [Section.stakeholders]: [...BASE_COMPANY_PATH, 'stakeholders'],
  [Section.esop]: [...BASE_COMPANY_PATH, 'esop'],
  [Section.education]: [...BASE_COMPANY_PATH, 'education'],
};

// TODO: thinking about using module navigation service instead
export const CAP_TABLE_MANAGE_SHARES_URL = `${getUrlFromPath(SECTION_PATH[Section.capTable])}/${
  ManageSharesRoutes.MANAGE_SHARES
}`;
