export enum PageName {
  Login = 'Login',
  ResetPassword = 'Reset Password',
  ForgotPassword = 'Forgot Password',
  CreatePassword = 'Create Password',
  Home = 'Company Home',
  Overview = 'Overview',
  Dashboard = 'Dashboard',
  CapTable = 'Cap Table',
  NotesAndLoans = 'Notes & Loans',
  ScenarioModeling = 'Scenario Modeling',
  FinancialIntegrations = 'Financial Integrations',
  KPIs = 'KPIs',
  SlicingPie = 'Slicing Pie',
  Stakeholders = 'Stakeholders',
  CompanySettings = 'Company Settings',
  UserSettings = 'User Settings',
  InvitationExpired = 'Invitation Expired',
  ErrorPage = 'ErrorPage',
  PaymentNeeded = 'Payment Needed',
  VerifyCode = 'Verify Code',
  VerifyEmail = 'Verify Email',
  VerifyFailed = 'Verify Failed',
  AcceptInvitation = 'Accept Invitation',
  EmailChangeApproval = 'Email Change Approval',
  WelcomeDemo = 'Welcome Demo',
  Onboarding = 'Onboarding',
  ComingSoon = 'Coming Soon',
  Financials = 'Financials',
  FinancialsRevenue = 'Financials | Revenue',
  FinancialsCosts = 'Financials | Costs',
  FinancialsProfitAndLoss = 'Financials | Profit And Loss',
  Documents = 'Documents |',
  DocumentsCommon = 'Documents | Common',
  DocumentsPersonal = 'Documents | Personal',
  DocumentsESOP = 'Documents | ESOP',
  DocumentsOnboarding = 'Documents | Onboarding',
  ESOP = 'ESOP',
  ESOPPools = 'ESOP | Pools',
  ESOPPlans = 'ESOP | Plans',
  ESOPGrants = 'ESOP | Grants',
  ESOPOrders = 'ESOP | Orders',
  ESOPCreatePlan = 'ESOP | Create Plan',
  ESOPEditPlan = 'ESOP | Edit Plan',
  ESOPCreateGrant = 'ESOP | Create Grant',
  ESOPCreateOrder = 'ESOP | Create Order',
  ESOPEditOrder = 'ESOP | Edit Order',
  ESOPCreateLeaver = 'ESOP | Create Leaver',
  OnboardingCC1 = 'Onboarding | Context Capture [1]',
  OnboardingCC2 = 'Onboarding | Context Capture [2]',
  OnboardingCompanyProfile = 'Onboarding | Company Profile Creation',
  OnboardingUserProfile = 'Onboarding | User Profile Creation',
  OnboardingPasswordSetup = 'Onboarding | Password Setup',
  OnboardingContinueToPlatform = 'Onboarding | Continue to Platform',
  GuidedOnboardingCompanyProfile = 'Guided Onboarding | Company Profile Creation',
  Education = 'Education',
}
