<div class="sidenav flex-column" [ngClass]="{'sidenav--collapsed': collapsed}">
  <div class="flex-row sidenav__controls margin-top-l margin-left-l margin-right-l">
    <div class="cursor-pointer navbar-item sidenav__hamburger-menu" (click)="toggleSidenav.emit()">
      <mat-icon class="sidenav__hamburger-icon" [svgIcon]="'menu'"></mat-icon>
    </div>
    <app-select
      *ngIf="!collapsed"
      class="sidenav__company-select"
      [options]="companies"
      [formControl]="companyControl"
      [isUnifiedHeightSet]="false"
      [isCreateOptionShown]="isSuperAdminOfAnyCompany"
      [isActiveOptionDisabled]="true"
      [compareFn]="compareById"
      createOptionLabel="Create New Company"
      (select)="handleChangeCompany($event)"
      (create)="handleCreateCompany($event)"
    ></app-select>
  </div>
  <div class="flex-column margin-top-l">
    <app-sidenav-item
      class="flex"
      *ngFor="let option of options"
      [option]="option"
      [active]="option.routerLink | callback: isOptionActive:activeOption"
      [url]="activeOption"
      [id]="option.id"
      [isSideNavCollapsed]="collapsed"
      (toggleSidenav)="handleToggleSidenav()"
    >
    </app-sidenav-item>
  </div>
  <div class="sidenav__bottom-section flex-column is-align-items-center margin-bottom-l">
    <img
      src="assets/images/logo.svg"
      [ngClass]="{'sidenav__logo--collapsed': collapsed}"
      class="sidenav__logo margin-bottom-m"
    />
    <div class="sidenav__bottom-section--item" [ngClass]="{'sidenav__bottom-section--item--collapsed': collapsed}">
      <div class="app-body-small margin-bottom-s" *ngIf="!collapsed">All rights reserved.</div>
      <div class="app-body-small" *ngIf="!collapsed">
        <a [href]="privacyPolicy" target="_blank" (click)="handlePrivacyPolicyClick($event)">Privacy Policy</a
        ><span class="anchor-color"> - </span><a [href]="cookiePolicy" target="_blank">Cookie Policy</a>
      </div>
      <div class="app-body-small margin-top-s" *ngIf="!collapsed">Version: {{ frontEndVersion }}</div>
    </div>
  </div>
</div>
