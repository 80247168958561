export enum Section {
  dashboard = 'dashboard',
  capTable = 'capTable',
  loansAndConvertibles = 'loansAndConvertibles',
  scenarioModeling = 'scenarioModeling',
  esop = 'esop',
  kpis = 'kpis',
  financials = 'financials',
  stakeholders = 'stakeholders',
  documents = 'documents',
  slicingPie = 'slicingPie',
  settings = 'settings',
  participant = 'participant',
  companySettings = 'companySettings',
  home = 'home',
  education = 'education',
}
